<template>
  <a :href="url" @click.prevent="openLink()" class="btn btn-application">
    <img :src="`/img/img-app-${platform}.svg`" :alt="platform" />
  </a>
</template>
<style lang="scss">
  @import '../assets/styles/components/btn';
</style>
<script>
export default {
  props: {
    platform: {
      required: true,
      type: String
    }
  },
  computed: {
    data () {
      return this.$t('routes.external')[this.platform]
    },
    url () {
      return this.data.url
    }
  },
  methods: {
    openLink () {
      window.open(this.url, '_blank').focus()
    }
  }
}
</script>
