import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueMeta from 'vue-meta'
import VueKinesis from 'vue-kinesis'
import VueYoutube from 'vue-youtube'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(VueMeta, { attribute: 'meta-id' })
Vue.use(VueKinesis)
Vue.use(VueYoutube)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
