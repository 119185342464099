import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang(en|cs|vi)',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home')
      },
      {
        path: 'cryptocurrencies',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'supported-cryptocurrencies',
            name: 'Supported Cryptocurrencies',
            component: () => import('../views/crypto/Supported')
          },
          {
            path: ':key(bitcoin|ethereum|cardano|decentraland)',
            component: () => import('../views/crypto/AboutCurrency')
          }
        ]
      },
      {
        path: 'platform',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'copy-trading',
            name: 'Copy Trading',
            component: () => import('../views/platform/CopyTrading')
          },
          {
            path: 'provide-strategies',
            name: 'Provide Strategies',
            component: () => import('../views/platform/ProvideStrategies')
          },
          {
            path: 'start-copying',
            name: 'Start Copying',
            component: () => import('../views/platform/StartCopying')
          },
          {
            path: 'dxmercato',
            name: 'DXMercato',
            component: () => import('../views/platform/DXMercato')
          }
        ]
      },
      {
        path: 'partners',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'affiliate-program',
            name: 'Affiliate Program',
            component: () => import('../views/partners/AffiliateProgram')
          }
        ]
      },
      {
        path: 'tools-and-resources',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'education',
            name: 'Education',
            component: () => import('../views/tools/Education')
          },
          {
            path: 'instructions',
            name: 'Step-by-Step Instructions',
            component: () => import('../views/tools/Instructions')
          },
          {
            path: 'cryptocurrency-terms',
            name: 'Cryptocurrency Terms',
            component: () => import('../views/tools/CryptocurrencyTerms')
          },
          {
            path: 'video-news',
            name: 'Video news',
            component: () => import('../views/tools/VideoNews')
          },
          {
            path: 'market-news',
            name: 'Market News',
            component: () => import('../views/tools/MarketNews')
          },
          {
            path: 'market-news/:id',
            name: 'Article',
            component: () => import('../views/tools/MarketNewsArticle')
          },
          {
            path: 'market-news-editor',
            name: 'Market News Editor',
            component: () => import('../views/tools/MarketNewsEditor')
          },
          {
            path: 'video-tutorials',
            name: 'Video Tutorials',
            component: () => import('../views/tools/VideoTutorials')
          }
        ]
      },
      {
        path: 'company',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'about-us',
            name: 'About Us',
            component: () => import('../views/company/AboutUs')
          },
          {
            path: 'sponzoring',
            name: 'Sponzoring',
            component: () => import('../views/company/Sponzoring')
          },
          {
            path: 'faq',
            name: 'FAQ',
            component: () => import('../views/company/Faq')
          },
          {
            path: 'security',
            name: 'Security',
            component: () => import('../views/company/Security')
          }
        ]
      },
      {
        path: 'policies',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'terms-and-conditions',
            name: 'Terms & Conditions',
            component: () => import('../views/policies/Tnc')
          },
          {
            path: 'privacy-policy',
            name: 'Privacy Policy',
            component: () => import('../views/policies/Privacy')
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  data: { isFirstLoad: true },
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   i18n.locale = to.params.lang ? to.params.lang : 'en'
//   store.state.isLoadingStart = true
//   next()
// })

function mergeQuery (to, from) {
  return { ...from.query, ...to.query }
}

router.beforeEach((to, from, next) => {
  // Preserve query parameters
  const mergedQuery = mergeQuery(to, from)
  if (JSON.stringify(mergedQuery) !== JSON.stringify(to.query)) {
    next({ path: to.path, query: mergedQuery, params: to.params })
  } else {
    i18n.locale = to.params.lang ? to.params.lang : 'en'
    store.state.isLoadingStart = true
    next()
  }
})

router.afterEach(() => {
  setTimeout(() => {
    store.state.isNavToggle = false
    store.state.isLoadingStart = false
    store.state.isLoadingFinish = true
    setTimeout(() => {
      store.state.isLoadingFinish = false
    }, 500)
  }, 800)
})

export default router
