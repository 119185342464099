<template>
  <router-link :to="`/${$i18n.locale}/${url}`">
    <slot name="l"></slot>
    <span>{{ data.txt }}</span>
    <slot name="r"></slot>
  </router-link>
</template>
<script>
export default {
  computed: {
    data () {
      return this.$parent.data
    },
    url () {
      return this.$t('routes')[this.data.type][this.data.key]
    }
  }
}
</script>
