<template>
  <component :is="comp" />
</template>
<style lang="scss">
  @import '../assets/styles/components/modal';
</style>
<script>
import m1 from '@/components/modal/ModalM1.vue'
import m2 from '@/components/modal/ModalM2.vue'
export default {
  props: {
    comp: {
      required: false,
      default: 1
    },
    data: {
      required: false
    }
  },
  components: {
    m1, m2
  }
}
</script>
