<template>
  <div class="modal modal--cookies" :class="{'active': !$store.state.isCookies}">
    <div class="modal__close-area"></div>
    <div class="modal__wrapper">
      <div class="d-flex flex-column flex-sm-row">
        <div class="img img--cookies mb-3 mb-sm-0 me-sm-3">
          <img src="/img/img-cookies.svg" alt="cookies" class="d-none d-sm-block">
          <img src="/img/img-cookies-2.svg" alt="cookies" class="d-sm-none">
        </div>
        <div>
          <p class="txt ts-12 ts-md-12 fw-400 fc-white mb-3">{{ cc.txt }}</p>
          <div class="cookies__hidden" v-if="isSettings">
            <div class="cookies__item">
              <div class="cookies__checkbox">
                <input id="cookies-1" type="checkbox" v-model="cookies1"/>
                <label for="cookies-1" class="cookies__checkbox-box" @click.prevent="toggleAllCookies()"></label>
                <label for="cookies-1" class="txt ts-12 ts-md-14 fw-400 fc-white" @click.prevent="toggleAllCookies()">{{ cc.checkboxes[0].tit }}</label>
              </div>
            </div>
            <div class="cookies__item">
              <div class="cookies__checkbox">
                <input id="cookies-2" type="checkbox" v-model="cookies2"/>
                <label for="cookies-2" class="cookies__checkbox-box"></label>
                <label for="cookies-2" class="txt ts-12 ts-md-14 fw-400 fc-white">{{ cc.checkboxes[1].tit }}</label>
              </div>
              <p class="txt ts-12 fw-400 fc-white">{{ cc.checkboxes[1].txt[0] }}</p>
            </div>
            <div class="cookies__item">
              <div class="cookies__checkbox">
                <input id="cookies-3" type="checkbox" v-model="cookies3"/>
                <label for="cookies-3" class="cookies__checkbox-box"></label>
                <label for="cookies-3" class="txt ts-12 ts-md-14 fw-400 fc-white">{{ cc.checkboxes[2].tit }}</label>
              </div>
              <p class="txt ts-12 fw-400 fc-white mb-2">{{ cc.checkboxes[2].txt[0] }}</p>
              <p class="txt ts-12 fw-400 fc-white">{{ cc.checkboxes[2].txt[1] }}</p>
            </div>
            <div class="cookies__item">
              <div class="cookies__checkbox">
                <input id="cookies-4" type="checkbox" v-model="cookies4"/>
                <label for="cookies-4" class="cookies__checkbox-box"></label>
                <label for="cookies-4" class="txt ts-12 ts-md-14 fw-400 fc-white">{{ cc.checkboxes[3].tit }}</label>
              </div>
              <p class="txt ts-12 fw-400 fc-white mb-2">{{ cc.checkboxes[3].txt[0] }}</p>
              <p class="txt ts-12 fw-400 fc-white">{{ cc.checkboxes[3].txt[1] }}</p>
            </div>
            <div class="cookies__item">
              <div class="cookies__checkbox">
                <input id="cookies-5" type="checkbox" v-model="cookies5"/>
                <label for="cookies-5" class="cookies__checkbox-box"></label>
                <label for="cookies-5" class="txt ts-12 ts-md-14 fw-400 fc-white">{{ cc.checkboxes[4].tit }}</label>
              </div>
              <p class="txt ts-12 fw-400 fc-white mb-2">{{ cc.checkboxes[4].txt[0] }}</p>
              <p class="txt ts-12 fw-400 fc-white">{{ cc.checkboxes[4].txt[1] }}</p>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <button type="button" class="btn btn-t1 btn-sm btn-sm-fc btn-blue me-3" @click.prevent="acceptCookies()">
              <span>{{ cc.btns[0] }}</span>
            </button>
            <button type="button" class="btn btn-t7 btn-settings btn-fc btn-blue" @click.prevent="isSettings = true" v-if="!isSettings">
              <img src="/img/icons/ui/ico-settings.svg" alt="Settings">
            </button>
            <button type="button" class="btn btn-t3 btn-sm btn-sm-fc btn-blue" @click.prevent="saveCookies()" v-if="isSettings">
              <span>{{ cc.btns[1] }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    cookies1: false,
    cookies2: true,
    cookies3: false,
    cookies4: false,
    cookies5: false,
    isSettings: false,
    storage: window.localStorage
  }),
  computed: {
    cc () {
      return this.$t('cookies')
    },
    isCookies () {
      return this.cookies2 && this.cookies3 && this.cookies4 && this.cookies5
    }
  },
  watch: {
    isCookies () {
      if (this.isCookies) {
        this.cookies1 = true
      } else {
        this.cookies1 = false
      }
    }
  },
  methods: {
    toggleCookiesModal () {
      this.$store.state.isCookies = true
      document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
    },
    acceptCookies () {
      this.enableAllCookies()
      this.saveSettings(true)
      setTimeout(() => {
        this.toggleCookiesModal()
      }, 200)
    },
    saveCookies () {
      this.saveSettings(this.isCookies)
      if (!this.isCookies) {
        if (!document.__defineGetter__) {
          Object.defineProperty(document, 'cookie', {
            get: function () { return '' },
            set: function () { return true }
          })
        } else {
          document.__defineGetter__('cookie', function () { return '' })
          document.__defineSetter__('cookie', function () {})
        }
      }
      this.toggleCookiesModal()
    },
    saveSettings (val) {
      this.storage.setItem('isCookies', val)
    },
    toggleAllCookies () {
      if (this.cookies1) {
        this.disableAllCookies()
      } else {
        this.enableAllCookies()
      }
    },
    enableAllCookies () {
      this.cookies1 = true
      this.cookies2 = true
      this.cookies3 = true
      this.cookies4 = true
      this.cookies5 = true
    },
    disableAllCookies () {
      this.cookies1 = false
      this.cookies2 = false
      this.cookies3 = false
      this.cookies4 = false
      this.cookies5 = false
    }
  },
  mounted () {
    if (!this.$store.state.isCookies) document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
  }
}
</script>
