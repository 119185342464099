<template>
  <footer class="footer">
    <div class="container footer__wrapper">
      <div class="row mb-2 mb-md-0">
        <div class="col-12 col-md-12 col-xl-8 mb-md-4 mb-xl-0">
          <footer-routes />
        </div>
        <div class="col-12 col-md-12 col-xl-3 offset-xl-1">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 order-lg-2 col-xl-12 order-xl-1">
              <footer-social />
            </div>
            <div class="col-12 col-md-6 col-lg-6 order-lg-1 col-xl-12 order-xl-2">
              <footer-app />
              <a class="src src--coinfy fw-400 ts-14 fc-dark" :href="`mailto:${$t('routes.external.email')}`">
                <i class="ico ico--link-coinfy">
                  <img src="/img/icons/ico-coinfy.svg" alt="email:">
                </i>
                <span>{{ $t('routes.external.email') }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <footer-content />
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="scss">
  @import '../assets/styles/components/footer';
</style>
<script>
import footerRoutes from '@/components/footer/FooterRoutes'
import footerSocial from '@/components/footer/FooterSocial'
import footerApp from '@/components/footer/FooterApplication'
import footerContent from '@/components/footer/FooterContent'
export default {
  components: {
    footerRoutes, footerSocial, footerApp, footerContent
  }
}
</script>
