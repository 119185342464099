<template>
  <div class="header__buttons">
    <btn class="me-3" :data="cc[0]" :theme="{type: 2, size: 'sm', width: $store.state.ww >= 1200 ? 'fc' : ''}"/>
    <btn :data="cc[1]" :theme="{type: 1, size: 'sm', width: $store.state.ww >= 1200 ? 'fc' : ''}"/>
  </div>
</template>
<script>
import Btn from '@/components/Btn'
export default {
  components: {
    Btn
  },
  computed: {
    cc () {
      return this.$t('header.btn')
    }
  }
}
</script>
