<template>
  <a :href="url" @click.prevent="openLink()">
    <slot name="l"></slot>
    <span>{{ data.txt }}</span>
    <slot name="r"></slot>
  </a>
</template>
<script>
export default {
  computed: {
    data () {
      return this.$parent.data
    },
    url () {
      let url = this.$t('routes')[this.data.type][this.data.key]
      if (['reg', 'log'].includes(this.data.key)) url += this.$store.state.urlParams
      return url
    }
  },
  methods: {
    openLink () {
      window.open(this.url, '_blank').focus()
    }
  }
}
</script>
