<template>
  <div class="footer__social">
    <h6 class="tit fw-400 ts-16 ts-md-18 fc-dark">{{ cc.tit }}</h6>
    <div class="footer__social-wrapper">
      <a class="footer__social-item" :class="`footer__social-item--${item.key}`" :href="url(item.key)"
         v-for="item in cc.list" :key="`footer-social-item-${item.key}`" @click.prevent="openLink(item.key)" />
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    cc () {
      return this.$t('footer.social')
    },
    routes () {
      return this.$t('routes.external')
    }
  },
  methods: {
    url (key) {
      return this.routes[key]
    },
    openLink (key) {
      const url = this.routes[key]
      window.open(url, '_blank').focus()
    }
  }
}
</script>
