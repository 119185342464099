<template>
  <div class="row">
    <div class="col-12">
      <hr class="footer__line"/>
    </div>
    <div class="col-12 col-lg-6 mb-3 mb-md-4">
      <p class="txt ts-12 fc-gray">
        {{ cc.txt.info }}
      </p>
    </div>
    <div class="col-12 col-lg-6 mb-3 mb-md-4">
      <p class="txt ts-12 fc-gray">
        <span class="txt fw-400 fc-gray">{{ cc.txt.jurisdiction[0] }}</span>
        {{ cc.txt.jurisdiction[1] }}
        <router-link :to="`/${$i18n.locale}/${url[cc.txt.jurisdiction[2].type][cc.txt.jurisdiction[2].key]}`" class="src fc-gray">{{ cc.txt.jurisdiction[2].txt }}</router-link>
      </p>
    </div>
    <div class="col-12 col-lg-6 col-xl-7 mb-3 mb-lg-0">
      <div class="footer__documents">
        <router-link :to="`/${$i18n.locale}/${url[item.type][item.key]}`" class="src ts-12 fc-gray" v-for="(item, i) in cc.documents" :key="`footer-document-item-${i}`">
          {{ item.txt }}
        </router-link>
      </div>
    </div>
    <div class="col-12 col-lg-6 col-xl-5">
      <p class="txt ts-12 fc-gray ta-lg-right">
        {{ cc.copyright[0] }} 2020-{{ new Date().getFullYear() }}
        {{ cc.copyright[1] }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    cc () {
      return this.$t('footer.content')
    },
    url () {
      return this.$t('routes')
    }
  }
}
</script>
