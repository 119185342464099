<template>
  <div class="header__routes">
    <div class="header__routes-row">
      <div class="header__routes-item" v-for="(item, i) in cc" :key="`routes-item-${i}`">
        <template v-if="item.type === 'expandable'">
          <div class="header__exp">
            <input type="checkbox" :id="isMob ? `exp-mob-${i}` : `exp-desk-${i}`" v-if="isMob" :checked="i === selExp" />
            <label class="header__exp-head" :for="isMob ? `exp-mob-${i}` : `exp-desk-${i}`" @click.prevent="tglExp(i)">
              <span>{{ item.txt }}</span>
              <i class="header__exp-arrow"></i>
            </label>
            <div class="header__exp-body">
              <div class="header__exp-item" v-for="(link, l) in item.child" :key="`routes-item-${i}-${l}`" @click="$store.commit('tglNav'); tglExp(i)">
                <a class="header__exp-link" :href="urlExt(item.key)" @click.prevent="openLink(urlExt(item.key))" v-if="link.type === 'external'">
                  <span>{{ link.txt }}</span>
                </a>
                <router-link class="header__exp-link" :to="urlInt(link.key)" tag="a" v-else>
                  <i class="header__exp-icon" :class="`header__exp-icon--${item.key}-${link.key}`"/>
                  <span>{{ link.txt }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'external'">
          <a class="header__routes-link" :href="urlExt(item.key)" @click.prevent="openLink(urlExt(item.key))">
            {{ item.txt }}
          </a>
        </template>
        <template v-if="item.type === 'internal'">
          <router-link class="header__routes-link" :to="urlInt(item.key)" tag="a">
            {{ item.txt }}
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isMob: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    selExp: -1
  }),
  computed: {
    cc () {
      return this.$t('header.routes')
    },
    routes () {
      return this.$t('routes')
    },
    routesExt () {
      return this.routes.external
    },
    routesInt () {
      return this.routes.internal
    }
  },
  methods: {
    tglExp (target) {
      this.selExp = this.selExp === target ? -1 : target
    },
    openLink (url) {
      window.open(url, '_blank').focus()
    },
    urlExt (key) {
      return this.routesExt[key]
    },
    urlInt (key) {
      return `/${this.$i18n.locale}/${this.routesInt[key]}`
    }
  }
}
</script>
