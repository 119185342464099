<template>
  <header class="header" :class="{'active': isOffset}">
    <div class="container header__wrapper d-none">
      <p class="header__wrapper--alert" v-html="cc.alert" />
    </div>
    <div class="container header__wrapper">
      <div class="header__col">
        <router-link class="header__logo" :to="`/${$i18n.locale}`" tag="a">
          <img src="/img/logo/logo-b.svg" alt="Coinfy" />
        </router-link>
        <header-routes />
      </div>
      <div class="header__col">
        <header-switcher />
        <header-toolbar />
      </div>
    </div>
    <div class="header__nav" :class="{'active': $store.state.isNavToggle}">
      <div class="header__nav-wrapper">
        <header-btn />
      </div>
      <div class="header__nav-wrapper">
        <header-routes :isMob="true"/>
      </div>
    </div>
  </header>
</template>
<style lang="scss">
  @import '../assets/styles/components/header';
</style>
<script>
import headerRoutes from '@/components/header/HeaderRoutes'
import headerToolbar from '@/components/header/HeaderToolbar'
import headerBtn from '@/components/header/HeaderBtn'
import headerSwitcher from '@/components/header/HeaderSwitcher'
export default {
  components: {
    headerRoutes, headerToolbar, headerBtn, headerSwitcher
  },
  computed: {
    cc () {
      return this.$t('header')
    }
  },
  data: () => ({
    isOffset: false
  }),
  methods: {
    evtPageOffset () {
      this.isOffset = window.pageYOffset > 0
    }
  },
  created () {
    window.addEventListener('scroll', this.evtPageOffset)
  }
}
</script>
