<template>
  <div class="header__toolbar">
    <div class="header__toolbar-row">
      <div class="header__toolbar-desk">
        <header-btn />
      </div>
      <div class="header__toolbar-mobi">
        <div class="header__toggle">
          <input type="checkbox" id="nav-toggle" v-model="$store.state.isNavToggle"/>
          <label for="nav-toggle">
            <i>
              <span></span>
            </i>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerBtn from '@/components/header/HeaderBtn'
export default {
  components: {
    headerBtn
  }
}
</script>
